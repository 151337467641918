import { checkPlankDataIsCorrect } from '@/util/commonFuncs'
import defaultTagData from '@/util/defaultTagData'
import { TaskType } from '@/views/taskList/types/taskCard'
import Vue from 'vue'
import Vuex from 'vuex'

import awaitPaibanStore from './modules/awaitPaibanStore'
import equipEnvironment from './modules/equipEnvironment'
import equipSetting from './modules/equipSetting'
import lang from './modules/lang'
import paibanStore from './modules/paibanStore'
import preferencesSetting from './modules/preferencesSetting'
import progressNotice from './modules/progressNotice'
import type { IStateType } from './types'

Vue.use(Vuex)

const store = new Vuex.Store<IStateType>({
  state: {
    // 生产线id
    settingId: '',
    // 用户信息
    userInfo: {},
    // 记录订单信息
    orderInfo: {},
    // 预处理数据参数
    preLayoutParam: {},
    // 记录渲染用的预处理数据
    preLayoutData: [],
    // 记录排版用的预处理数据
    prePaibanData: [],
    // 排版数据
    paibanData: [],
    // 记录处理好的分类之后的排版数据
    finalDrawData: [],
    // nc设置
    ncSetting: {},
    // 排版接口额外信息
    paibanExtraInfo: {},
    // 记录所有的大板信息
    bigPartsInfo: {},
    // 记录是否是查看的历史记录
    isHistoryStatus: false,
    // 记录请求排版的参数
    paibanParam: {},
    tagValue: [],
    tagPrintSetting: {},
    // 记录数据来源
    dataFrom: '',
    // 记录预料自定义时的余料板件的index
    supplusIndex: 100000,
    // 记录老板良跳转过来之后是否有操作权限
    hasLblAuth: true,
    resetData: {},
    editPwdvisible: false,
    activePaibanWay: ['paiban_v2'],
    // 记录排版和精细排版中暂存区板件
    tempStorage: [],
    // 记录最终保存时的暂存区板件
    finalStorage: [],
    // 记录是否正在拖拽板件
    isDragingPlank: false,
    // 记录拖拽的暂存区板件
    beDraggedPlank: null,
    // 记录暂存区拖拽板件时, 鼠标位置
    tempImgPos: null,
    editNamevisible: false,
    showPerson: false,
    isShowSettingPop: false,
    isShowFindPop: false,
    isShowHelp: false,
    // 记录排版时使用的余料信息
    surplusParams: {},
    // 记录特殊板材参数
    specialPlankParams: {},
    // 记录第三方数据字段
    guimenDataKeys: [],
    thinkerxMaterialKeys: [],
    // 记录是否来自QT
    QtFrom: '',
    // 收集printTag里面转换成的图片
    saveCollect: [],
    // 记录是否打印标签
    hasPrintTag: false,
    // 记录点击开始排版已选中
    recodeSelectPart: {
      selects: [],
      isHistory: false,
      // 用于判定是不是刚刚登录
      login: true,
    },
    // 纪录选中余料
    recodeSelectSurplus: {
      selects: [],
      isHistory: false,
      // 用于判定是不是刚刚登录
      login: true,
    },
    // 保留排版时间
    paibanTime: '',
    // 是否显示公告
    showNotice: false,
    // 是否打开重要功能宣传模态框
    showImportFunc: false,
    // 是否有未读公告
    noReadNotice: false,
    // 长用余料模板
    surplusCommonSize: [],
    // 余料裁剪方式
    surplusTailorWay: null,
    // 余料裁剪优先组合 L 形余料
    isCombineToLShape: true,
    // 记录排版方案
    paibanInfo: {},
    // 是否开启余料自动裁剪
    surplusAutoTailor: false,
    // 记录是否已经执行过一次余料裁剪
    recodeIsTailor: false,
    // 鼠标相对于缓存区里面图片的偏移
    offsetPointbetweenCacheImage: {
      x: 0,
      y: 0,
    },
    // 标签列表对应的dom
    tagListDomString: '',
    // 标签列表显示大小与真正的模板标签大小之间的比例
    tagScale: 1,
    // 当前模板
    curTemplate: {},
    // 标签列表长度（不包含余料标签）
    tagListLength: 0,
    // 排版key
    paibanKey: '',
    // 记录是否是第一次排版，用于分配孔槽
    isResetPaibanAllocation: false,
    // 具有New tag的元素
    newTags: {},
    // 记录特殊标记绘制的透明度
    drawOpacity: 0.24,
    // 记录特殊标记绘制是否自适应
    drawSizeAuto: true,
    // 记录特殊标记绘制的缩放大小
    drawSize: 1,
    // 记录标签模板中是否存在自定义特殊标签
    isExistcusSpecialTag: false,
    // 记录是否需要显示样表表头
    isShowSample: true,
    // 记录样表数据
    sampleTableData: {
      address: '',
      clientData: '',
      factoryOrderNum: '',
      roomName: '',
      orderRemark: '',
    },
    // 记录是否是通过链接进入云排版
    isLinkLogin: false,
    // 老板良空间ID
    factoryId: '',
    // 记录生产线
    proLineList: [],
    // 最大矩形裁剪   裁剪最小尺寸
    // 长边
    longSideRect: null,
    // 短边
    shortSideRect: null,
    // 是否是单个材质push到perPaibanData
    isSinglePushPrePaibanData: false,
    // 记录所有的刀
    allKnifes: null,
    errorPathPlankID: [],
    // 记录排版使用的补件
    availableBujianList: [],
    //记录开料清单中补件展示状态
    trigger: false,
    // 记录是否展示余料
    isShowSurplusList: false,
    //记录排版生产线选择
    recodrProductionLineChoose: '',
    // 记录筛选后的开料单
    filterMaterialList: null,
    // 记录搜索筛选前的开料单
    beforeSearchMaterialList: null,
    // 记录全部的开料板件
    materialList: null,
    // 记录筛选的条件
    filterObject: {},
    filterCondition: {},
    // 记录尺寸筛选前的板件列表
    beforeSizeFilterList: null,
    // 记录是否是否是试生产订单
    isTrialProduct: false,
    // 试生产工艺设置状态
    trailProductState: 0,
    produceFileUrl: '',
    // 记录所有可用的标签
    ncLabel: [],
    // 保存标签模板
    tagTempList: [{ id: 0, name: '默认标签模板', data: defaultTagData }],
    //记录开料清单中补件数据选择状态
    bujianListChooseStatus: [],
    //记录开料清单中补件全选按钮的选中状态
    isSelectAll: false,
    // 第三方平台板件数据
    thirdPlankData: null,
    //是否选择“生成单个文件”
    isOpenSingleFile: false,
    // 历史订单
    historyOrder: null,
    highgloss_direction: '',
    hasRollPoly: false,
    isSubPrintTag: false,
    // 是否打印标签
    isPrintTag: false,
    // 余料标签序号
    plankLableId: 1,
    // 贴标机文件名称
    labeling_folder: '',
    // 记录是否余料不翻版
    isSurplusNoRoll: false,
    //记录冲突显示当前冲突小板
    recordCurrentConflictBoard: {},
    specialRemarks: {},
    // 是否创建任务卡片
    hasSendToTask: false,
    // 记录跳转至开料清单的任务
    materialTask: [] as TaskType[],
    // 记录今天批次次数
    produceTime: 0,
    // 记录批次排版数据
    batchPaiban: null,
    //批量生产的批次号
    batchNo: '',
    // 是否批量生产
    isBatch: false,
    // 是否是本地导入(排除了json的情况，其他情况孔槽显示置为0孔0槽)
    isLocalImport: false,
    // 是否新排板
    isNewPaiban: false,
    lockedPlank: [],
    // 历史修边尺寸
    historyPlankEdgeOff: 0,
    // 历史正面再修边尺寸
    historySecondTrimValue: 0,
    // 是否重新排版
    hasHistoryChangePaiban: false,
    // newNC的返回值
    newNCRes: null,
    // 记录标签字体大小
    frontLabelFont: {
      fontPt: 8,
      fontSize: 10,
      fontFamily: '微软雅黑',
      fontWeight: '',
    },
    currentProductLineName: '',
    // 生成的文件内容
    folderContents: {},
    repeatFileName: [],
    tempFileName: [],
    isLoadingColors: false,
    materialColorList: [],
    mergeSubFolderData: null,
    mergeFolderSetting: null,
    // 是否切割顺序正常可以打印标签
    isCanPrintTag: false,
    // isFoldMaterialList: false, // 是否折叠开料清单列表
    // 颜色材质的数据回显
    newMaterialColorList: [],
    newMaterialMatCode: '',
    // 原片的数据回显
    baseMaterialColorList: [],
    baseMaterialMatCode: '',
    // 余料的数据回显
    supplusStorageColorList: [],
    supplusStorageMatCode: '',
    selectStandardPlank: {},
    // 默认选用标准大板
    defaultSelectedPlank: {},
    // 自定义翻译
    customerTranslateData: null,
    // 自定义大板排版顺序
    customPlankOrder: false,
    // 自定义大板排版顺序-大板列表
    plankOrderList: [],
    // 保存生产线、试生产接口信息
    saveInfo: {},
    onlyLayoutTooBig: false,
    // 预排版设置
    preLayoutSetting: null,
    // 是否预排版设置修改
    isPreLayoutSettingChanged: false,
    // 排版用预排版设置
    oriLayoutSetting: null,
    // 记录参与排版的余料参数
    surplusObj: null,
    // 开料清单添加板件需要回显的数据
    needReviewData: {},
    // 记录导入json的次数
    importCount: 0,
    // 超过数据限制
    limitOvers: [],
    // 开料清单所有的余料
    allSurplusList: [],
    // 开料清单勾选的余料
    selectSurplusList: [],
    isFollowExportTag: false, // 标签是否顺带导出
    isShowDisPaiabnConfirm: false, //小板分散排版弹窗提示
    isLockedRePaiban: false, // 锁定大板后 是否重新排版了
  },

  mutations: {
    setSettingId(state, val) {
      state.settingId = val
    },
    setrecordCurrentConflictBoard(state, val) {
      state.recordCurrentConflictBoard = val
    },
    setRecodrProductionLineChoose(state, val) {
      state.recodrProductionLineChoose = val
    },
    setMPRzReference(state, val) {
      state.MPRzReference = val
    },
    setBujianListChooseStatus(state, val) {
      state.bujianListChooseStatus = val
    },
    setErrorPathPlankID(state, val) {
      state.errorPathPlankID.push(val)
      state.errorPathPlankID = (
        [...new Set(state.errorPathPlankID)] as number[]
      ).sort((a, b) => a - b)
    },
    // 兼容之前的设置，设置默认值
    onChangeIsShowSurplusList(state, val = false) {
      if (val) state.isShowSurplusList = !val
      else state.isShowSurplusList = !state.isShowSurplusList
    },
    setShowSurplusList(state, val) {
      state.isShowSurplusList = val
    },
    // 兼容之前，设置默认值
    onChangeTrigger(state, val) {
      state.trigger = val
    },
    clearErrorPathPlankID(state) {
      state.errorPathPlankID.length = 0
    },
    setLongSideRect(state, val) {
      state.longSideRect = val
    },
    setShortSideRect(state, val) {
      state.shortSideRect = val
    },
    // 记录排版方案
    setPaibanInfo(state, val) {
      state.paibanInfo = val
    },
    // 记录是否打印过标签
    setHasPrintTag(state, val) {
      state.hasPrintTag = val
    },
    //个人中心弹窗显隐
    setShowPerson(state, val) {
      state.showPerson = val
    },
    setShowSettingPop(state, val) {
      state.isShowSettingPop = val
    },
    setShowFindPop(state, val) {
      state.isShowFindPop = val
    },
    setIsShowHelp(state, val) {
      state.isShowHelp = val
    },
    // 记录用户信息
    setUserInfo(state, val) {
      state.userInfo = val
    },
    // 记录预处理数据参数
    setPreLayoutParam(state, val) {
      window.sessionStorage.setItem('materialParams', JSON.stringify(val))
      state.preLayoutParam = val
    },
    // 记录订单信息
    setOrderInfo(state, val) {
      state.orderInfo = val
    },
    // 记录预处理数据
    setPreLayoutData(state, val) {
      const props = ['path', 'oPath', 'holes']
      state.preLayoutData.splice(0, state.preLayoutData.length)
      val.forEach((part: any) => {
        const newPart = {
          ...part,
          oriCurveHoles: part.hasOwnProperty('oriCurveHoles')
            ? part.oriCurveHoles
            : part.curveHoles
            ? part.curveHoles
            : [],
        }
        props.forEach((prop) => {
          if (newPart.hasOwnProperty(prop)) {
            Object.freeze(newPart[prop])
          }
        })
        state.preLayoutData.push(newPart)
      })
      // 使用Object.freeze冻结数据，避免vue2将其转为响应式数据产生的性能问题
      // 前置条件：确保数据不会再被修改
      // state.preLayoutData = Object.freeze(layoutData)
    },
    // 记录用于排版的预处理数据
    setPrePaibanData(state, val) {
      state.prePaibanData = val
    },
    // 记录排版数据
    setPaibanData(state, val) {
      // state.paibanData = val
      state.paibanData.splice(0, state.paibanData.length)
      val.forEach((item: any) => {
        state.paibanData.push(item)
      })
    },
    // 记录最终处理好的数据
    setFinalDrawData(state, val) {
      state.finalDrawData = val
    },
    // 记录nc设置
    setNcSetting(state, val) {
      state.ncSetting = val
    },
    // 记录排版接口返回的额外信息
    setPaibanExtraInfo(state, val) {
      state.paibanExtraInfo = val
    },
    // 记录大板信息
    setBigPartsInfo(state, val) {
      state.bigPartsInfo = val
    },
    // 切换是否为历史记录状态
    changeHistoryStatus(state, val) {
      state.isHistoryStatus = val
    },
    // 清除排版相关数据(用于设备对接保存后的重置)
    clearPaibanData(state) {
      state.paibanData = []
      state.paibanExtraInfo = {}
      state.bigPartsInfo = {}
    },
    savePrintData(state, value) {
      state.tagValue = value.data
      state.tagPrintSetting = value.setting
      window.sessionStorage.removeItem('materialParams')
    },
    // 更改数据来源
    changeDataFrom(state, val) {
      state.dataFrom = val
    },
    // 设置余料编号
    setSupplusIndex(state) {
      state.supplusIndex += 1
    },
    // 更改老板良跳转过来的操作权限
    changeLblAuth(state, val) {
      state.hasLblAuth = val
    },
    // 修改密码弹窗显隐
    setEditPwdvisible(state, val) {
      state.editPwdvisible = val
    },
    // 添加板件到暂存区
    addTempStorage(state, val) {
      state.tempStorage.push(val)
    },
    // 添加板件到最终暂存区
    addFinalStorage(state, val) {
      state.finalStorage.push(val)
    },
    // 删除或清空暂存区板件
    deleteTempStorage(state, val) {
      if (val == 'clear') {
        state.tempStorage = []
      } else {
        state.tempStorage.splice(val, 1)
      }
    },
    // 删除或清空最终暂存区板件
    deleteFinalStorage(state, val) {
      if (val == 'clear') {
        state.finalStorage = []
      } else {
        state.finalStorage.splice(val, 1)
      }
    },
    // 切换暂存区板件状态
    changeTempStorage(state) {
      state.finalStorage = JSON.parse(JSON.stringify(state.tempStorage))
    },
    // 从精细排版返回的时候, 将保存的暂存区数据赋给页面渲染的暂存区数组
    resetTempStorage(state) {
      state.tempStorage = JSON.parse(JSON.stringify(state.finalStorage))
    },
    // 切换是否在拖动板件的状态
    changeDragingPlank(state, val) {
      state.isDragingPlank = val
    },
    // 拖拽板件时, 记录板件
    recordBeDraggedPlank(state, val) {
      state.beDraggedPlank = val
    },
    // 修改昵称弹窗显隐
    setEditNamevisible(state, val) {
      state.editNamevisible = val
    },
    // 记录所有state值的初始值
    recordAllState(state) {
      const deepCopy = function (p: any, c?: any) {
        c = c || {}
        for (const i in p) {
          if (i != 'resetData') {
            if (typeof p[i] === 'object' && p[i] !== null) {
              c[i] = p[i].constructor === Array ? [] : {}
              deepCopy(p[i], c[i])
            } else {
              c[i] = p[i]
            }
          }
        }
        return c
      }
      state.resetData = deepCopy(state)
    },
    // 清除所有记录的信息
    clearAllInfo(state) {
      for (const i in state) {
        if (i != 'resetData') {
          state[i] = state.resetData[i]
        }
      }
    },
    // 记录当前排版方案
    setActivePaibanWay(state, val) {
      state.activePaibanWay = val
    },
    // 记录拖拽暂存区板件是, 鼠标相对于图片的位置
    setTempImgPos(state, val) {
      state.tempImgPos = val
    },
    setOffsetPointbetweenCacheImage(state, val) {
      state.offsetPointbetweenCacheImage = val
    },
    // 记录余料参数
    setSurplusParams(state, val) {
      state.surplusParams = val
    },
    // 删除记录中的余料参数
    deleteSurplusParams(state, val) {
      // 直接从保存历史进入排版页surplusParams为空对象，会阻塞程序无法删除余料，需要做个兜底
      if (Object.keys(state.surplusParams).length) {
        for (let i = 0; i < state.surplusParams[val.title].length; ++i) {
          const surplus = state.surplusParams[val.title][i]
          if (surplus.ggid == val.ggid && surplus.id == val.id) {
            state.surplusParams[val.title].splice(i, 1)
            break
          }
        }
      }
    },
    // 记录特殊板材菜蔬
    setSpecialPlankParams(state, val) {
      state.specialPlankParams = val
    },
    // 记录第三方跳转过来的数据字段
    recordGuimenDataKeys(state, val) {
      state.guimenDataKeys = val
    },
    // 记录自定义标签模板中需要展示的第三方数据的可选字段
    setThinkerxMaterialKeys(state, val) {
      state.thinkerxMaterialKeys = val
    },
    // 更改QT来源
    changeQtFrom(state, from) {
      state.QtFrom = from
    },
    saveCollect(state, val) {
      state.collect = val
    },
    savetagListDomString(state, val) {
      state.tagListDomString = val
    },
    setRecodeSelectPart(state, val) {
      state.recodeSelectPart = val
    },
    setRecodeSelectSurplus(state, val) {
      state.recodeSelectSurplus = val
    },
    setPaibanTime(state, val) {
      state.paibanTime = val
    },
    setShowNotice(state, val) {
      state.showNotice = val
    },
    setImportFunc(state, val) {
      state.showImportFunc = val
    },
    setNoReadNotice(state, val) {
      state.noReadNotice = val
    },
    setSurplusCommonSize(state, val) {
      state.surplusCommonSize = val
    },
    setSurplusTailorWay(state, val) {
      state.surplusTailorWay = val
    },
    setSurplusAutoTailor(state, val) {
      state.surplusAutoTailor = val
    },
    setIsCombineToLShape(state, val) {
      state.isCombineToLShape = val
    },
    setRecodeIsTailor(state, val) {
      state.recodeIsTailor = val
    },
    setTagScale(state, val) {
      state.tagScale = val
    },
    // 设置当前模板
    setCurTemplate(state, val) {
      state.curTemplate = val
    },
    //设置标签列表长度
    setTagListLength(state, val) {
      state.tagListLength = val
    },
    // 设置排版key
    setPaibanKey(state, val) {
      state.paibanKey = val
    },
    setIsResetPaibanAllocation(state, val) {
      state.isResetPaibanAllocation = val
    },

    setNewTags(state, val) {
      state.newTags = val
    },
    setDrawOpacity(state, val) {
      state.drawOpacity = val
    },
    setDrawSizeAuto(state, val) {
      state.drawSizeAuto = val
    },
    setDrawSize(state, val) {
      state.drawSize = val
    },

    setIsExistcusSpecialTag(state, val) {
      state.isExistcusSpecialTag = val
    },
    setIsShowSample(state, val) {
      state.isShowSample = val
    },
    setSampleTableData(state, val) {
      state.sampleTableData = { ...state.sampleTableData, ...val }
    },
    setIsLinkLogin(state, val) {
      state.isLinkLogin = val
    },
    setFactoryId(state, val) {
      state.factoryId = val
    },
    setProLineList(state, val) {
      state.proLineList = val
    },
    setIsSinglePushPrePaibanData(state, val) {
      state.isSinglePushPrePaibanData = val
    },
    setAllKnifes(state, val) {
      state.allKnifes = val
    },
    setAvailableBujianList(state, val) {
      state.availableBujianList = val
    },
    setFilterMaterialList(state, val) {
      // debugger
      // state.filterMaterialList = val
      const props = ['path', 'oPath', 'holes']
      if (!state.filterMaterialList) {
        state.filterMaterialList = []
      }
      state.filterMaterialList.splice(0, state.filterMaterialList.length)
      val?.forEach((plank: any, index: number) => {
        plank.parts.forEach((part: any) => {
          const newPart = {
            ...part,
            oriCurveHoles: part.hasOwnProperty('oriCurveHoles')
              ? part.oriCurveHoles
              : part.curveHoles
              ? part.curveHoles
              : [],
          }
          props.forEach((prop) => {
            if (newPart.hasOwnProperty(prop)) {
              Object.freeze(newPart[prop])
            }
          })
          part = {
            ...newPart,
          }
          // if (!state.filterMaterialList[index]) {
          //   state.filterMaterialList[index] = []
          // }
          // state.filterMaterialList[index].push(newPart)
        })
        if (!state.filterMaterialList[index]) {
          state.filterMaterialList[index] = {}
        }
        state.filterMaterialList[index] = {
          ...plank,
        }
      })
    },
    setMaterialList(state, val) {
      state.materialList = val
    },
    setFilterObject(state, val) {
      state.filterObject = val || {}
    },
    setBeforeSearchMaterialList(state, val) {
      // state.beforeSearchMaterialList = val
    },
    setBeforeSizeFilterList(state, val) {
      // state.beforeSizeFilterList = val
    },
    setIsTrialProduct(state, val) {
      state.isTrialProduct = val
    },
    setTrailProductState(state, val) {
      state.trailProductState = val
    },
    setProduceFileUrl(state, val) {
      state.produceFileUrl = val
    },
    setNcLabel(state, val) {
      state.ncLabel = val
    },
    setTempList(state, val) {
      state.tagTempList = val
    },
    setThirdPlankData(state, val) {
      state.thirdPlankData = val
    },
    setOpenSingleFile(state, val) {
      state.isOpenSingleFile = val
    },
    setHistoryOrder(state, val) {
      state.historyOrder = val
    },
    setHighglossDirection(state, val) {
      state.highgloss_direction = val
    },
    setHasRollPoly(state, val) {
      state.hasRollPoly = val
    },
    setIsSubPrintTag(state, val) {
      state.isSubPrintTag = val
    },
    setIsPrintTag(state, val) {
      state.isPrintTag = val
    },
    setPlankLableId(state, val) {
      state.plankLableId = val
    },
    // 修改贴标起始值
    setFrontLabelingStartNum(state, val) {
      state.ncSetting.front_labeling_start_num = val
    },
    // 设置贴标文件名称
    setLabelingFolder(state, val) {
      state.labeling_folder = val
    },
    setHistoryPlankEdgeOff(state, val) {
      state.historyPlankEdgeOff = val
    },
    setHistorySecondTrimValue(state, val) {
      state.historySecondTrimValue = val
    },
    // 设置余料不翻版
    setSurplusNoRoll(state, val) {
      state.isSurplusNoRoll = val
    },
    setSpecialRemarks(state, val) {
      state.specialRemarks = val
    },
    setHasSendToTask(state, val) {
      state.hasSendToTask = val
    },
    setMaterialTask(state, val) {
      state.materialTask = val
    },
    setProduceTime(state, val) {
      state.produceTime = val
    },
    setBatchPaiban(state, val) {
      state.batchPaiban = val
    },
    setBatchNo(state, val) {
      state.batchNo = val
    },
    setIsBatch(state, val) {
      state.isBatch = val
    },
    setIsLocalImport(state, val) {
      state.isLocalImport = val
    },
    setIsNewPaiban(state, val) {
      state.isNewPaiban = val
    },
    setLockedPlank(state, val) {
      if (val.length) {
        state.lockedPlank = val.filter((item: any) => {
          return item.isLocked
        })
      } else {
        state.lockedPlank = []
      }
    },
    addLockedPlankInAll(state) {
      state.lockedPlank.forEach((item: Record<string, any>, index: number) => {
        item.stockNum = state.paibanData.length + 1 + index
        item.stockKey = `${item.texture}:${item.matCode}:${item.thick}:${item.stockNum}`
        checkPlankDataIsCorrect(item)
      })
      state.paibanData = state.paibanData.concat(state.lockedPlank)
      state.paibanData.forEach((item) => {
        delete item._sortNum
      })
    },
    setChangePaiban(state, val) {
      state.hasHistoryChangePaiban = val
    },
    setNewNCRes(state, val) {
      state.newNCRes = val
    },
    setfrontLabelFont(state, val) {
      state.frontLabelFont = val
    },
    setCurrentProductLineName(state, val) {
      state.currentProductLineName = val
    },
    setFolderContents(state, val) {
      state.folderContents = val
    },
    setRepeatFileName(state, val) {
      state.repeatFileName = val
    },
    setTempFileName(state, val) {
      state.tempFileName = val
    },
    setIsLoadingColors(state, val) {
      state.isLoadingColors = val
    },
    setMaterialColorList(state, val) {
      state.materialColorList = val
    },
    // setOnlyShowSurplusChoice(state, val) {
    //   state.onlyShowSurplusChoice = val
    // },
    setMergeSubFolderData(state, val) {
      state.mergeSubFolderData = val
    },
    setMergeFolderSetting(state, val) {
      state.mergeFolderSetting = val
    },
    setIsCanPrintTag(state, val) {
      state.isCanPrintTag = val
    },
    // TODO: 需求变更，暂时不用
    // 修改是否折叠开料清单状态
    // setIsFoldMaterialList(state, val) {
    //   state.isFoldMaterialList = val
    // },
    // 为了做数据回显 开料清单
    setNewMaterialColorList(state, val) {
      state.newMaterialColorList = val
    },
    setNewMaterialMatCode(state, val) {
      state.newMaterialMatCode = val
    },
    // 原片
    setBaseMaterialColorList(state, val) {
      state.baseMaterialColorList = val
    },
    setBaseMaterialMatCode(state, val) {
      state.baseMaterialMatCode = val
    },
    // 余料
    setSupplusStorageColorList(state, val) {
      state.supplusStorageColorList = val
    },
    setSupplusStorageMatCode(state, val) {
      state.supplusStorageMatCode = val
    },
    setSelectStandardPlank(state, val) {
      state.selectStandardPlank = val
    },
    setDefaultSelectedPlank(state, val) {
      state.defaultSelectedPlank = val
    },
    setCustomerTranslateData(state, val) {
      state.customerTranslateData = val
    },
    setCustomPlankOrder(state, val) {
      state.customPlankOrder = val
    },
    setPlankOrderList(state, val) {
      state.plankOrderList = val
    },
    setSaveInfo(state, val) {
      state.saveInfo = val
    },
    // 预排版设置
    setPreLayoutSetting(state, val) {
      state.preLayoutSetting = val
    },
    // 是否预排版设置变更
    setIsPreLayoutSettingChanged(state, val) {
      state.isPreLayoutSettingChanged = val
    },
    // 保存排版用预排版设置
    setOriLayoutSetting(state, val) {
      state.oriLayoutSetting = val
    },
    // 记录排版参数
    setSurplusObj(state, val) {
      state.surplusObj = val
    },

    // 开料清单筛选条件
    setFilterCondition(state, val) {
      state.filterCondition = val
    },
    // 开料清单添加板件需要回显的数据
    setNeedReviewData(state, val) {
      state.needReviewData = val
    },
    // 记录json的导入次数
    setImportCount(state, val) {
      state.importCount = val
    },
    // 设置颜色是否超过限制数据
    setLimitOvers(state, val) {
      state.limitOvers = val
    },
    setAllSurplusList(state, val) {
      state.allSurplusList = val
    },
    setSelectSurplusList(state, val) {
      state.selectSurplusList = val
    },
    setIsFollowExportTag(state, val) {
      state.isFollowExportTag = val
    },
    setIsShowDisPaiabnConfirm(state, val) {
      state.isShowDisPaiabnConfirm = val
    },
    setIsLockedRePaiban(state, val) {
      state.isLockedRePaiban = val
    },
  },
  actions: {},
  modules: {
    equipEnvironment,
    equipSetting,
    paibanStore,
    awaitPaibanStore,
    preferencesSetting,
    progressNotice,
    lang,
  },
})
export default store
