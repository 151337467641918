<template>
  <div id="home" @click.stop="hideUserInfo" v-loading="isReloadLang">
    <a-layout
      id="components-layout-demo-custom-trigger"
      v-if="!$route.meta.hideHead"
    >
      <a-layout-sider
        v-model="collapsed"
        :trigger="null"
        collapsible
        style="background: #fff"
        :collapsedWidth="collapsedWidth"
        :width="spreadWidth"
      >
        <div class="logo pt10 pl16" v-if="!showSpecificFunc">
          <img src="../assets/icon/site-logo.jpg" width="40" height="40" />
          <div class="text" v-show="!collapsed">
            <h3>
              <span
                style="font-size: 20px"
                :title="translateLang('main.head.title')"
                >{{ $t('main.head.title') }}</span
              >
              <span class="version">{{ version }}</span>
            </h3>
            <span :title="translateLang('main.head.subTitle')">{{
              $t('main.head.subTitle')
            }}</span>
          </div>
        </div>
        <a-menu
          theme="light"
          mode="inline"
          :defaultOpenKeys="['sub1', 'sub2', 'sub3', 'sub4']"
          :selectedKeys="activeMenu"
        >
          <a-sub-menu key="sub1" class="item-sub-menu">
            <span slot="title"
              ><i class="iconfont icon-prduction"></i>
              <span
                v-show="!collapsed"
                id="produce_optimize_btn"
                :title="translateLang('main.sideBar.title')"
                >{{ $t('main.sideBar.title') }}</span
              >
            </span>
            <a-menu-item key="material" :selectable="false">
              <router-link
                to="/materialList"
                id="open-material_btn"
                :title="translateLang('main.sideBar.materialList')"
              >
                {{ $t('main.sideBar.materialList') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="draw" :disabled="!ableViewPaiban">
              <router-link
                to="/newPaiban"
                id="typesetting_scheme_btn"
                :title="translateLang('main.sideBar.paibanResult')"
              >
                {{ $t('main.sideBar.paibanResult') }}
              </router-link>
            </a-menu-item>
            <a-menu-item
              key="printTag"
              :disabled="!hasPrintTag || !isCanPrintTag"
            >
              <router-link
                to="/printTag"
                id="print_tag_btn"
                :title="translateLang('main.sideBar.printTag')"
              >
                {{ $t('main.sideBar.printTag') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="history">
              <router-link
                to="/paibanHistory"
                id="save_history_btn"
                :title="translateLang('main.sideBar.history')"
              >
                {{ $t('main.sideBar.history') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="workShop" class="factory relative">
              <!-- <div
                class="factory-tip absolute pv1 ph5 br5 r20"
                style="background-color: #f9e0c7; line-height: 20px"
                :title="translateLang('main.sideBar.limitDate')"
              >
                {{ $t('main.sideBar.limitDate') }}
              </div> -->
              <router-link
                to="/workShopProduction"
                id="equipment_Online_btn"
                :title="translateLang('main.head.workeShop')"
              >
                {{ $t('main.head.workeShop') }}
              </router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="sub2" class="item-sub-menu">
            <span slot="title"
              ><i class="iconfont icon-equipment"></i>
              <span
                v-show="!collapsed"
                :title="translateLang('main.head.productionLine')"
                >{{ $t('main.head.productionLine') }}</span
              >
            </span>
            <a-menu-item key="equip">
              <router-link
                to="/equipment"
                id="equipment_docking_btn"
                :title="translateLang('main.head.prolineManager')"
              >
                {{ $t('main.head.prolineManager') }}
              </router-link>
            </a-menu-item>
            <a-menu-item
              key="preLayoutSetting"
              class="preLayout-setting relative"
            >
              <div
                class="factory-tip absolute pv1 ph5 br5 r20"
                style="background-color: #f9e0c7; line-height: 20px"
                :title="translateLang('main.sideBar.limitDate')"
              >
                {{ $t('main.sideBar.limitDate') }}
              </div>
              <router-link
                to="/preLayoutSetting"
                id="pre_layout_setting_btn"
                :title="translateLang('preLayoutSetting.title')"
              >
                {{ $t('preLayoutSetting.title') }}
              </router-link>
              <!-- TODO wc 新功能展示 -->
              <!-- <div
                class="inline-block w100"
                style="padding-left: 40px"
                to="/preLayoutSetting"
                id="pre_layout_setting_btn"
                :title="translateLang('preLayoutSetting.title')"
                @click="handleClickPreSetting"
              >
                <div class="inline-block flex flex-cross--center">
                  <span class="inline-block ellipsis" style="max-width: 100%">{{
                    $t('preLayoutSetting.title')
                  }}</span>
                  <span
                    v-if="!isNewSettingRecord"
                    class="inline-block ml10 new_button_text ellipsis"
                    :title="translateLang('common.newSetting')"
                    >{{ $t('common.newSetting') }}</span
                  >
                </div>
              </div> -->
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="sub3" class="item-sub-menu">
            <span slot="title"
              ><i class="iconfont icon-mobanguanli"></i>
              <span
                v-show="!collapsed"
                id="setting_btn"
                :title="translateLang('main.head.setting')"
                >{{ $t('main.head.materialManager') }}</span
              >
            </span>
            <a-menu-item key="baseMaterial">
              <router-link
                to="/baseMaterial"
                id="initial_plank_manage_btn"
                :title="translateLang('main.head.plankManager')"
              >
                {{ $t('main.head.plankManager') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="bujianManage">
              <router-link
                to="/bujianManage"
                :title="translateLang('main.head.suplumentManager')"
              >
                {{ $t('main.head.suplumentManager') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="surplus">
              <router-link
                to="/supplusStorage"
                id="excess_material_manage_btn"
                :title="translateLang('main.sideBar.surplusManager')"
              >
                {{ $t('main.sideBar.surplusManager') }}
              </router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="sub4" class="item-sub-menu">
            <span slot="title"
              ><i class="iconfont icon-set_up01"></i>
              <span v-show="!collapsed" id="setting_btn">{{
                $t('main.head.setting')
              }}</span>
            </span>

            <a-menu-item key="tagMenage">
              <router-link
                to="/printTag?from=temp"
                id="tag_manage_btn"
                :title="translateLang('main.head.tagManager')"
              >
                {{ $t('main.head.tagManager') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="fileManage">
              <router-link
                to="/fileManage"
                id="file_manage_btn"
                :title="translateLang('main.head.fileManager')"
                >{{ $t('main.head.fileManager') }}</router-link
              >
            </a-menu-item>
            <!-- 更多设置 -->
            <a-menu-item key="moreSettings">
              <router-link
                to="/moreSettings"
                id="more_settings_btn"
                v-bp="'moreSettings:click_more_settings'"
                >{{ $t('main.sideBar.moreSettings') }}</router-link
              >
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="deepseek" v-if="isGG" class="deep_item">
            <div @click="handleToSfe" class="deepbox">
              <img src="../assets//image/deepseek.png" />
              <div>{{ $t('main.sideBar.deepSeekTitle') }}</div>
            </div>
          </a-menu-item>
        </a-menu>
        <div
          id="feedback_showFeedback_div"
          @click="handleShowFeedback"
          class="fixed flex flex-main--center flex-cross--center b20 cursor-pointer"
        >
          <i class="el-icon-service"></i>
        </div>
      </a-layout-sider>
      <a-layout class="flex-box--1 flex flex-dir--top">
        <a-layout-header
          style="height: 60px; padding: 0; background: #212121"
          v-if="!showSpecificFunc"
        >
          <div class="flex flex-cross--center">
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (collapsed = !collapsed)"
            />
            <!-- <GBreadcrumb></GBreadcrumb> -->
            <a-button
              type="link"
              class="topNav-btn"
              :class="{ 'active-topNav': activeTopNav == 'taskList' }"
              @click="handleTaskList"
              id="topNav_taskList_btn"
              :title="translateLang('main.head.task')"
              >{{ $t('main.head.task') }}</a-button
            >
            <a-button
              type="link"
              class="topNav-text"
              :disabled="!ableViewPaiban"
              :class="{ active: !ableViewPaiban }"
              @click="handleToPaiban"
              :title="translateLang('main.head.arrange')"
              >{{ $t('main.head.arrange') }}</a-button
            >
            <a-button
              type="link"
              class="topNav-text"
              @click="handleToSurplus"
              :title="translateLang('main.head.surplus')"
              >{{ $t('main.head.surplus') }}</a-button
            >
          </div>
          <top-nav @onReloadLang="handleReloadLang" />
        </a-layout-header>
        <a-layout-content
          :style="layoutMainStyle"
          ref="layout_main"
          class="flex-box--1"
        >
          <!-- 缓存标签打印，防止每次进入组件都会创建 -->
          <keep-alive include="printTag">
            <router-view />
          </keep-alive>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <!-- <topNav ref="top_nav" :nowNavItem="nowNavItem" :showTiangong="showTiangong" v-if="navShow"></topNav> -->
    <router-view v-else v-on:getNoReadNotice="getNoReadNotice" />
    <!-- 公告 -->
    <notice v-if="isShowNotice"></notice>
    <!-- 重要功能宣传 -->
    <MImportFuncModal />
    <GFeedback
      :showFeedback="showFeedback"
      @handleFeedback="handleFeedback"
      ref="feedback"
    ></GFeedback>
    <!-- 全局通知小球 -->
    <g-global-progress-notice-ball> </g-global-progress-notice-ball>
  </div>
</template>

<script>
import { buryPoint } from '@/apis/common/buryPoint'
import { httpRequest } from '@/apis/request'
import { isTest } from '@/apis/request'
import imgWrite from '@/assets/image/write.png'
import GBaseModal from '@/components/g-base-modal.vue'
import GBreadcrumb from '@/components/g-breadcrumb'
import GFeedback from '@/components/g-feedback'
import GGlobalProgressNoticeBall from '@/components/g-global-progress-notice-ball.vue'
import mImportFuncModal from '@/components/importFunc/m-importFuncModal.vue'
import MImportFuncModal from '@/components/importFunc/m-importFuncModal.vue'
import NewContent from '@/components/newContent.vue'
import notice from '@/components/notice'
import topNav from '@/components/topNav.vue'
import { translate } from '@/util/commonFun'
import { buryPointApi } from '@/util/commonFuncs'
import { compareVersions } from '@/util/versionCompare'
import { mapMutations } from 'vuex'
import { mapState } from 'vuex'

import store from '../store'
import { createTask } from '../views/taskList/util/dealData'

export default {
  components: {
    topNav,
    notice,
    GBreadcrumb,
    GFeedback,
    GGlobalProgressNoticeBall,
    NewContent,
    MImportFuncModal,
  },
  data() {
    return {
      showSpecificFunc: false,
      nowNavItem: 'equipment',
      showTiangong: false,
      showHead: true,
      collapsed: false,
      // 左边导航栏展开宽度
      spreadWidth: 200,
      // 左边导航栏收起宽度
      collapsedWidth: 80,
      // 版本号
      version: '',
      img: {
        imgWrite,
      },
      showFeedback: false,
      sendTaskModal: null,
      isReloadLang: false,
      isNewSettingRecord: false,
    }
  },
  computed: {
    ...mapState(['hasSendToTask', 'preLayoutData', 'isCanPrintTag']),
    navShow() {
      let routeMetaNav = this.$route.meta.nav
      return routeMetaNav ? true : false
    },
    // 是否可以查看排版页面
    ableViewPaiban() {
      if (this.$store.state.paibanData.length) {
        return true
      } else {
        return false
      }
    },
    // 是否可以查看打印标签
    hasPrintTag() {
      return this.$store.state.hasPrintTag
    },
    layoutMainStyle() {
      let width = this.collapsed ? this.collapsedWidth : this.spreadWidth
      return {
        margin: '0',
        padding: '0',
        position: 'relative',
        height: 'calc(100vh - 60px)',
        width: `calc(100vw - ${width}px)`,
        overflowX: 'auto',
        background: '#fff',
      }
    },
    // 顶部激活按钮
    activeTopNav() {
      let path = this.$route.path.replace('/', '')
      return path
    },
    // 标签页面通过菜单进入活跃按钮不同
    activeMenu() {
      let path
      if (
        this.$route.path == '/printTag' ||
        this.$route.path == '/editTagTem'
      ) {
        path = this.$route.query.from ? 'tagMenage' : this.$route.meta.nav
      } else if (this.$route.path == '/taskList') {
        path = ''
      } else {
        path = this.$route.meta.nav
      }
      return [path]
    },
    isShowNotice() {
      return this.$store.state.showNotice
    },
    isGG() {
      const userInfo = this.$store.state.userInfo
      const { platform, userPlatform } = userInfo
      return userPlatform === 'guigui' || platform === 0
    },
  },
  methods: {
    ...mapMutations(['setNewTags', 'setHasSendToTask']),
    handleClickMoreSettings() {
      buryPoint({
        function_module: 'moreSettings',
        function_point: 'click_more_settings',
      })
    },
    translateLang(key) {
      return translate(key)
    },
    // 是否在加载语言
    handleReloadLang(isReloadLang) {
      if (!isReloadLang) {
        setTimeout(() => {
          this.isReloadLang = isReloadLang
        }, 500)
      } else {
        this.isReloadLang = isReloadLang
      }
    },
    //修改个人中心弹窗显隐
    hideUserInfo() {
      if (this.$store.state.showPerson) {
        this.$store.commit('setShowPerson', false)
      }
      if (this.$store.state.isShowSettingPop) {
        this.$store.commit('setShowSettingPop', false)
      }
      if (this.$store.state.isShowFindPop) {
        this.$store.commit('setShowFindPop', false)
      }
      if (this.$store.state.isShowHelp) {
        this.$store.commit('setIsShowHelp', false)
      }
    },
    // 保存用户token
    saveUserToken() {
      if (this.$route.query.guigui_token) {
        window.sessionStorage.setItem('token', this.$route.query.guigui_token)
      }
    },
    // 获取版本
    getVersion() {
      this.$get('/latest_bluen_version', {}, (res) => {
        if (res.status == 1) {
          this.version = res.data.version
          this.setNewTags(res.data.new_feature_tag)
        }
      })
    },
    handleFeedback(showFeedback) {
      this.showFeedback = showFeedback
    },
    handleShowFeedback() {
      this.showFeedback = true
      this.$refs.feedback.getFeedbackData()
    },
    handleTaskList() {
      if (this.$route.path !== '/taskList') {
        if (!this.hasSendToTask && this.preLayoutData.length) {
          this.sendTaskModal = this.$antdConfirm({
            title: this.$t('common.tip'),
            content: this.$t('materialPage.createTaskCard'),
            okText: this.$t('common.confirm'),
            cancelText: this.$t('common.cancel'),
            centered: true,
            closable: true,
            onOk: () => {
              this.isTaskLoading = true
              createTask(this.preLayoutData).then(() => {
                this.isTaskLoading = false
                this.setHasSendToTask(true)
                this.$router.push('/taskList')
                buryPointApi('task', 'creat_task')
              })
            },
            onCancel: (e) => {
              this.handleCancelToTask(e)
            },
          })
        } else {
          this.$router.push('/taskList')
        }
      }
    },
    handleToSurplus() {
      this.$router.push('/supplusStorage')
    },
    handleToPaiban() {
      this.$router.push('/newPaiban')
    },
    handleCancelToTask(e) {
      const isCancel = typeof e == 'function'
      this.sendTaskModal && this.sendTaskModal.destroy()
      if (isCancel) {
        this.$router.push('/taskList')
      }
    },
    async getNoReadNotice() {
      let { id } = this.$store.state.userInfo
      if (!id) {
        await httpRequest.post('get_user_info').then((res) => {
          id = res.data.id
        })
      }
      const isFirst = sessionStorage.getItem('firstNotice')
      this.$token('/notice_list', { uid: id, limit: 20, page: 1 }, (res) => {
        if (res.status == 1) {
          const { unread_amount } = res.data
          if (unread_amount > 0) {
            this.$store.commit('setNoReadNotice', true)
            // 第一次登录并且有未读公告弹出公告通知
            if (!isFirst) {
              this.$store.commit('setShowNotice', true)
            }
          } else {
            this.$store.commit('setNoReadNotice', false)
          }
        }
      })
    },
    handleClickPreSetting(event) {
      this.isNewSettingRecord = true
      this.$router.push('/preLayoutSetting')
    },
    handleToSfe() {
      const params = {
        platform: 'bluen',
        token: window.sessionStorage.getItem('token'),
        isSelectDeepSeek: true,
      }
      // 定义 URL 参数
      const urlParams = `?platform=${params.platform}&token=${params.token}&isSelectDeepSeek=${params.isSelectDeepSeek}`
      if (window.webToQt) {
        // 柜柜客户端
        buryPoint({
          function_module: 'deepseekQT',
          function_point: 'deepseekqt',
        })

        const gg_version =
          sessionStorage.getItem('guigui_version') || '4.0.0.3-17'
        const pageName =
          compareVersions(gg_version, '4.0.0.3-17') === 1 ? 'deepSeek' : 'chat'

        if (window.mainBridge?.openSFETaskByParams) {
          window.mainBridge.openSFETaskByParams({
            urlParams,
            pageName,
          })
        } else {
          this.$antdConfirm({
            content: translate('common.versionErrTip'),
          })
          return
        }
      } else {
        // ypb 网页端
        buryPoint({ function_module: 'deepseek', function_point: 'deepseekPC' })
        window.open(`${process.env.VUE_APP_TO_SFE_URL}${urlParams}`, '_blank')
      }
    },
  },
  watch: {
    '$route.meta': function (newVal, oldVal) {
      if (newVal.hideHead) {
        this.showHead = false
      } else {
        this.showHead = true
      }
    },
  },
  mounted() {
    if (this.$route.meta.hideHead) {
      this.showHead = false
    } else {
      // 请求接口，展示公告弹窗 isShowNotice
      this.getNoReadNotice()
    }
    if (this.$route.query.from) {
      if (this.$route.query.from == 'tiangong') {
        this.showTiangong = true
      }
    } else {
      this.showTiangong = false
      this.saveUserToken()
    }
  },
  created() {
    document.title = '云排版'
    this.showSpecificFunc = window?.showSpecificFunc
    if (this.$route.query) {
      if (this.$route.query.from == 'tiangong') {
        document.title = '天工'
      }
    }
    const settingStorage = localStorage.getItem('newSettingRecord')
    const userInfo = this.$store.state.userInfo
    this.isNewSettingRecord =
      settingStorage &&
      JSON.parse(settingStorage)[userInfo.id] &&
      JSON.parse(settingStorage)[userInfo.id]['hasClickPreSetting']

    this.getVersion()
  },
}
</script>

<style scoped lang="less">
#home {
  /* min-width: 1440px; */
  width: 100%;
  background: #eee;
}

#components-layout-demo-custom-trigger {
  .trigger {
    padding: 0 16px;
    color: #fff;
    //导航栏头部左边样式
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #18a8c7;
    }
  }
  .topNav-btn {
    color: #fff;
  }
  .topNav-text {
    color: #fff;
    &:hover {
      color: #18a8c7;
    }
  }
  .topNav-btn:hover,
  .active-topNav {
    background-color: #4c4c4c;
  }
  .logo {
    //导航栏左边logo样式
    display: flex;
    gap: 8px;
    align-items: flex-start;
    // margin: 16px;
    width: 100%;
    height: 60px;
    // justify-content: center;
    // padding: 10px 0 0 16px;
    background: #212121;
    // background: rgba(255, 255, 255, 0.2);
    img {
      display: block;
      border-radius: 6px;
    }
    div.text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 40px;
      line-height: 1;
      h3 {
        // line-height: 28px;
        margin-bottom: 0;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        font-family: 'PingFangSC-Medium', 'PingFang SC';
        letter-spacing: 1px;
      }
      span {
        color: #fff;
        font-weight: 400;
        font-size: 12px;
        font-family: 'PingFangSC-Regular', 'PingFang SC';
        // line-height: 17px;
      }
      .version {
        font-weight: 400;
        font-size: 13px;
      }
    }
  }

  section.ant-layout header.ant-layout-header {
    //导航栏头部样式
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 800px;
    z-index: 999;
  }
  /deep/ .ant-layout-sider-children {
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
    .ant-menu-root {
      flex: 1;
      height: 0;
      overflow-y: auto;
      background: #eee;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .ant-menu-inline {
      background: #eee;
    }
    #feedback_showFeedback_div {
      left: 135px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #1f1f1f;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
      transition: all 0.3s ease;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: linear-gradient(145deg, #272727, #141414);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5),
          0px 8px 15px rgba(0, 0, 0, 0.2);
        z-index: -1;
        transition: all 0.3s ease;
      }
      &:hover {
        transform: translateY(-5px);
      }
      &:hover::before {
        box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5),
          0px 12px 20px rgba(0, 0, 0, 0.25);
      }
      i {
        color: #fff;
        font-size: 26px;
      }
    }
  }
  /deep/ ul.ant-menu.ant-menu-vertical {
    border: none;
  }
  /deep/ ul.ant-menu.ant-menu-inline {
    //导航栏左侧菜单
    border: none;
    > li {
      &.item-sub-menu {
        //有子菜单的样式
        div.ant-menu-submenu-title {
          //有子菜单的菜单标题
          padding-left: 16px !important;
          text-overflow: ellipsis;
          overflow: hidden;
          > span {
            padding-left: 17px;
            i {
              color: #a3a5b4;
            }
            span {
              padding-left: 3px;
              color: #333333;
              font-weight: 400;
              font-size: 14px;
              font-family: 'PingFangSC-Regular', 'PingFang SC';
            }
          }
          > i::after,
          > i::before {
            //箭头
            background-image: linear-gradient(to right, #a3a5b4, #a3a5b4);
          }
        }
        .preLayout-setting {
          overflow: unset !important;
          .new-button {
            width: 100%;
          }
          a {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }
        }
        &.ant-menu-submenu-active {
          //悬浮在子菜单item项时item的样式
          div.ant-menu-submenu-title {
            color: #18a8c7;
            > span {
              padding-left: 17px;
              i {
                color: #18a8c7;
              }
              span {
                color: #18a8c7;
              }
            }
            > i::after,
            > i::before {
              //箭头
              background-image: linear-gradient(to right, #18a8c7, #18a8c7);
            }
          }
        }
        ul li.ant-menu-item-active a {
          color: #18a8c7;
        }
        ul li.ant-menu-item {
          padding-left: 16px !important;
          a {
            padding-left: 40px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        &.ant-menu-submenu-selected {
          //item项被选中时的样式
          div.ant-menu-submenu-title {
            > span {
              padding-left: 17px;
              i {
                color: #18a8c7;
              }
              span {
                color: #18a8c7;
              }
            }
            > i::after,
            > i::before {
              //箭头
              background-image: linear-gradient(to right, #18a8c7, #18a8c7);
            }
          }
          ul li.ant-menu-item-selected {
            background-color: transparent;
            &::after {
              border: none;
            }
            a {
              color: #fff;
              background: #18a8c7;
              border-radius: 4px;
            }
          }
        }
      }
      &.item-single {
        //没有子菜单的样式
        padding-left: 16px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        a {
          padding-left: 18px;
          color: #ffffff;
          font-weight: 400;
          font-size: 14px;
          font-family: 'PingFangSC-Regular', 'PingFang SC';
          background: transparent;
          border-radius: 4px;
          i {
            color: #a3a5b4;
          }
          span {
            padding-left: 3px;
            color: #333;
          }
        }
        &.ant-menu-item-active a {
          //没有子菜单的选中悬浮的样式
          i,
          span {
            color: #18a8c7;
          }
        }
        &.ant-menu-item-selected {
          //没有子菜单的选项被选中时的样式
          background-color: transparent;
          &::after {
            border: none;
          }
          a {
            background: #18a8c7;
            i,
            span {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  /deep/ ul.ant-menu-inline-collapsed {
    //折叠起来的样式
    li {
      background-color: transparent;
      &.ant-menu-submenu-selected {
        div.ant-menu-submenu-title > span {
          color: #18a8c7;
        }
      }
      &.ant-menu-item-selected {
        a i {
          color: #18a8c7;
        }
      }
    }
  }
}
.imgWrite {
  right: -10px;
}
.active {
  color: #a8a4a4 !important;
}
/deep/ .ant-layout-content {
  overflow-x: auto !important;
}
/deep/ .ant-layout::-webkit-scrollbar {
  display: none;
  // opacity: 0;
}
/deep/ .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
  list-style-position: inside;
  list-style-type: disc;
}
.new_button_text {
  background-color: red;
  border-radius: 3px;
  color: white;
  height: 24px;
  text-align: center;
  min-width: 40px;
  padding: 3px;
  line-height: 18px;
}

/deep/ .ant-menu-item-selected {
  #pre_layout_setting_btn {
    color: #fff;
    background: #18a8c7;
    border-radius: 4px;
  }
}
.deep_item {
  margin: 10px 0px;
  width: 100% !important;
  line-height: 100% !important;
  padding-left: 16px !important;
  .deepbox {
    display: flex;
    flex-direction: column;
    padding-left: 17px;
    img {
      width: 80px;
      object-fit: cover;
    }
    div {
      width: 80px;
      margin-top: 5px;
      margin-left: 22px;
      height: fit-content;
      background: linear-gradient(270deg, #ca37ce, #335bff);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
}
</style>
